<template>
  <div class="Page">
    <h1 class="test">黑名单</h1>

  </div>
</template>

<script>
export default {
  name: "BlackList",
  data() {
    return {};
  },
  created(opt) {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .test {
    color: $test;
  }
}
</style>
